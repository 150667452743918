import "./CompanyFiles.scss";
import { GrDocumentZip } from "react-icons/gr";
import { FcDocument } from "react-icons/fc";
import { copyTextToClipboard } from "../../helpers/navigator";
import { bank } from "../../data/bank__info";
import { CopyIcon } from "@chakra-ui/icons";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import DirectionsMap from "../../components/map/DirectionsMap";
const ict_docs = [
  {
    title: "ДТЕП",
    link: "https://ictwork.site/files/DTEP.zip",
    img: <GrDocumentZip />,
  },
  {
    title: "Логістик",
    link: "https://ictwork.site/files/LOGISTIC.zip",
    img: <GrDocumentZip />,
  },
  {
    title: "Фрахт",
    link: "https://ictwork.site/files/FRAKHT.zip",
    img: <GrDocumentZip />,
  },
];
const fop_docs = [
  {
    title: "Гладій Л.О.",
    link: "https://ictwork.site/files/GLADIY.zip",
    img: <GrDocumentZip />,
  },
  {
    title: "Рубель О.М.",
    link: "https://ictwork.site/files/RYBEL.zip",
    img: <GrDocumentZip />,
  },
  {
    title: "Сніжко Н.М.",
    link: "https://ictwork.site/files/SNIZHKO.zip",
    img: <GrDocumentZip />,
  },
  {
    title: "Соломенцева З.Ю.",
    link: "https://ictwork.site/files/SOLOMENCEVA.zip",
    img: <GrDocumentZip />,
  },
  {
    title: "Теклишин Г.І.",
    link: "https://ictwork.site/files/TEKLYSHYN.zip",
    img: <GrDocumentZip />,
  },
  {
    title: "Чернега А.В.",
    link: "https://ictwork.site/files/CHERNEGA.zip",
    img: <GrDocumentZip />,
  },
  {
    title: "Аршулік М.В.",
    link: "https://ictwork.site/files/ARSHYLIK.zip",
    img: <GrDocumentZip />,
  },
  {
    title: "Ларічева Н.А.",
    link: "https://ictwork.site/files/LARICHEVA.zip",
    img: <GrDocumentZip />,
  },
];
const doc__word = [
  {
    title: "Опитувальний лист",
    link: "https://ictwork.site/files/ICT_LIST.doc",
    img: <FcDocument />,
  },
  {
    title: "Заява про ... персональних даних",
    link: "https://ictwork.site/files/OPD_ZAY.doc",
    img: <FcDocument />,
  },
];

const dog_strah = [
  {title:'Договір страхування',link:"https://ictwork.site/files/DOG_STRAHYVANNYA.pdf",img: <FcDocument />}
]
const certificate = [
  {title:'ICT_HUNGARIA_ENG',link:"https://ictwork.site/files/ICT_HUNGARIA_KFT_ENG.pdf",img: <FcDocument />},
  {title:'ICT_HUNGARIA_UKR',link:"https://ictwork.site/files/ICT_HUNGARIA_KFT_UKR.pdf",img: <FcDocument />},
  {title:'ICT_LOGISTICS_KFT_ENG',link:"https://ictwork.site/files/ICT_LOGISTICS_KFT_ENG.pdf",img: <FcDocument />},
  {title:'ICT_LOGISTICS_KFT_UKR',link:"https://ictwork.site/files/ICT_LOGISTICS KFT_UKR.pdf",img: <FcDocument />},
  {title:'ICT_ZAKHID_ENG',link:"https://ictwork.site/files/ICT_ZAKHID_ENG.pdf",img: <FcDocument />},
  {title:'ICT_ZAKHID_UKR',link:"https://ictwork.site/files/ICT_ZAKHID_UKR.pdf",img: <FcDocument />},
  {title:'ICT_WEST_LOGISTIC_ENG',link:"https://ictwork.site/files/ICT_WEST_LOGISTIC_ENG.pdf",img: <FcDocument />},
  {title:'ICT_WEST_LOGISTIC_UKR',link:"https://ictwork.site/files/ICT_WEST_LOGISTIC_UKR.pdf",img: <FcDocument />},
  {title:'ICT_FRAKHT_ENG',link:"https://ictwork.site/files/ICT_FRAKHT_ENG.pdf",img: <FcDocument />},
  {title:'ICT_FRAKHT_UKR',link:"https://ictwork.site/files/ICT_FRAKHT_UKR.pdf",img: <FcDocument />},

]
const CompanyFiles = () => {
  return (
    <div className="company__files container">
      <Tabs>
        <TabList>
          <Tab>Документи ІСТ та ФОП</Tab>
          <Tab>Реквізити</Tab>
          <Tab>Сертифікати</Tab>
        </TabList>
        <TabPanel>
          <div className="files">
            <div className="ict__docs">
              <h3>Документи ICT</h3>
              <div className="docs__block">
                {ict_docs.map((item, idx) => {
                  return (
                    <a
                      key={idx}
                      className="document__link-container"
                      href={item.link}
                      download
                    >
                      <div className="document__info-container">
                        {item.img}

                        <span className="document__name">{item.title}</span>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="ict__docs">
              <h3>Документи ФОП</h3>
              <div className="docs__block">
                {fop_docs.map((item, idx) => {
                  return (
                    <a
                      key={idx}
                      className="document__link-container"
                      href={item.link}
                      download
                    >
                      <div className="document__info-container">
                        {item.img}

                        <span className="document__name">{item.title}</span>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
            <div className="ict__docs">
              <h3>Документи для контраентів</h3>
              <div className="docs__block">
                {doc__word.map((item, idx) => {
                  return (
                    <a
                      key={idx}
                      className="document__link-container"
                      href={item.link}
                      download
                    >
                      <div className="document__info-container">
                        {item.img}

                        <span className="document__name">{item.title}</span>
                      </div>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </TabPanel>

        <TabPanel>
          <div className="ict__bank">
            {bank.map((item, idx) => {
              return (
                <div
                  key={idx}
                  onClick={() =>
                    copyTextToClipboard(item.title + `\n\n${item.text}`)
                  }
                  className="bank__info"
                >
                  <CopyIcon />
                  <p style={{ fontSize: "20px" }}>{item.title}</p>
                  <p>{item.text}</p>
                </div>
              );
            })}
          </div>
        </TabPanel>


        <TabPanel>
<div className="certificates">
<div className="certificates__dog">
{
  dog_strah.map((item,idx) =>{
    return (
      <a
        key={idx}
        className="document__link-container"
        href={item.link}
        download
      >
        <div className="document__info-container">
          {item.img}

          <span className="document__name">{item.title}</span>
        </div>
      </a>
    );
  })
}
</div>
<div className="certificates__items">
{
  certificate.map((item,idx) =>{
    return (
      <a
        key={idx}
        className="document__link-container"
        href={item.link}
        download
      >
        <div className="document__info-container">
          {item.img}

          <span className="document__name">{item.title}</span>
        </div>
      </a>
    );
  })
}
</div>
</div>
        </TabPanel>
      </Tabs>


      <div className="certificate"></div>
    </div>
  );
};

export default CompanyFiles;
